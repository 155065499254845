import { createContext, useContext, useState, useRef, useEffect } from 'react';

const SliderContext = createContext();

export const SliderProvider = ({ children, setSelectedOffer }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);
  const tabsCreativesRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.style.transform = `translateX(-${currentIndex * 100}%)`;
    }
  }, [currentIndex]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
    const firstCreativesTab = tabsCreativesRef?.current?.firstChild;
    if (firstCreativesTab) {
      firstCreativesTab.click();
    }
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  const previousSlide = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
    setSelectedOffer('');
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  return (
    <SliderContext.Provider value={{ currentIndex, nextSlide, previousSlide, sliderRef, tabsCreativesRef }}>
      {children}
    </SliderContext.Provider>
  );
};

export const useSlider = () => useContext(SliderContext);
