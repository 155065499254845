import { basicLengthAndCharValidation, basicLengthValidation } from '../../../utils/helpers';

import consts from '../../../utils/consts';
import { MESSENGER_NAMES } from '../../../components/Access/TellUsAboutYou/TellUsAboutYouRolesSubdivisions/TellUsAboutYouRoleDropdownInput/TellUsAboutYouRoleDropdownInput.react';

const { PHONE, SKYPE, WHATSAPP, TELEGRAM, WEBSITE_WITHOUT_PROTOCOL, NUMERIC } = consts.REGEXES;

export const messengerValidations = {
  [MESSENGER_NAMES.SKYPE]: SKYPE,
  [MESSENGER_NAMES.WHATSAPP]: WHATSAPP,
  [MESSENGER_NAMES.TELEGRAM]: TELEGRAM,
};

export default (values) => {
  const getErrorMessage = (value) => {
    switch (values?.accountType) {
      case 'Webmaster':
        return 'Please enter a valid url';
      case 'Social Marketer':
      case 'Influencer / Content Creator':
        return basicLengthValidation(value, 3, 30);
      case 'Media Buyer':
        return 'Please enter a valid number';
      case 'Affiliate Network':
      case 'Ad Network':
        return 'Please enter a valid url';
    }
  };
  const errors = {};

  if (!values.accountType) {
    errors.accountType = 'Please choose an Account Type';
  }
  if (values.accountType === 'Webmaster') {
    if (
      values.website1 === '' ||
      values.website1 === undefined ||
      values.website1 === null ||
      !values.website1?.match(WEBSITE_WITHOUT_PROTOCOL)
    ) {
      errors.website1 = getErrorMessage(values.website1);
    }
    if (values.website2 !== '' && values.website2 !== undefined && !values.website2?.match(WEBSITE_WITHOUT_PROTOCOL)) {
      errors.website2 = getErrorMessage(values.website2);
    }
    if (values.website3 !== '' && values.website2 !== undefined && !values.website3?.match(WEBSITE_WITHOUT_PROTOCOL)) {
      errors.website3 = getErrorMessage(values.website3);
    }
  }

  if (values.accountType === 'Social Marketer' || values.accountType === 'Influencer / Content Creator') {
    if (values?.social1 === '') {
      errors.social1 = 'Please choose a Social Media';
    }
    if (values?.social2 === '' && values.handle2?.length > 0) {
      errors.social2 = 'Please choose a Social Media';
    }
    if (values?.social3 === '' && values.handle3?.length > 0) {
      errors.social3 = 'Please choose a Social Media';
    }

    if (values?.handle1 === '' || !!values?.handle1 || values?.handle1 === undefined || values?.handle1 === null) {
      errors.handle1 = getErrorMessage(values?.handle1);
    }
    if (values?.handle2) {
      errors.handle2 = getErrorMessage(values?.handle2);
    }
    if (values?.handle3) {
      errors.handle3 = getErrorMessage(values?.handle3);
    }
  }
  if (values.accountType === 'Media Buyer') {
    const formattedNumber = values?.budget?.replaceAll('$', '').replaceAll(',', '');
    if (values.budget === '' || values.budget === undefined || values.budget === null) {
      errors.budget = 'Please enter your budget';
    } else if (!formattedNumber.match(NUMERIC)) {
      errors.budget = getErrorMessage(values.budget);
    }
  }

  if (values.accountType === 'Ad Network' || values.accountType === 'Affiliate Network') {
    if (
      values.network === '' ||
      values.network === undefined ||
      values.network === null ||
      !values.network?.match(WEBSITE_WITHOUT_PROTOCOL)
    ) {
      errors.network = getErrorMessage(values.network);
    }
  }

  if (values.affiliateType === 'Company' && (!values.company || !values.company.length)) {
    errors.company = "Please enter your company's name";
  }

  if (!values.vertical.length) {
    errors.vertical = 'Please choose the vertical(s) you want to promote';
  }
  if (!values.traffic.length) {
    errors.traffic = 'Please select your top geos';
  }

  if (!values.messaging_platform_name) {
    errors.messaging_platform_name = 'Please choose your messaging platform of choice';
  }

  if (
    !values.messaging_platform_username ||
    !values.messaging_platform_username?.match(messengerValidations[values.messaging_platform_name])
  ) {
    errors.messaging_platform_username = 'Invalid messaging platform information.';
  }

  if (values.phone !== '' && values.phone !== undefined && !values.phone?.match(PHONE)) {
    errors.phone = 'Please enter a valid phone number.';
  } else if (values.phone === '' && values.phone === undefined) {
    return true;
  }

  if (!values.country) {
    errors.country = 'Please select the country.';
  } else {
    if (values.country === 'CA') {
      if (!values.province) {
        errors.province = 'Please select the province you live in.';
      } else {
        errors.province = basicLengthAndCharValidation(values?.province);
      }
    } else if (values.country === 'US') {
      if (!values.state) {
        errors.state = 'Please select the state you live in.';
      } else {
        errors.state = basicLengthAndCharValidation(values?.state);
      }
    }
  }

  if (values.promo) {
    errors.promo = basicLengthAndCharValidation(values?.promo, 1, 30);
  }

  if (values.otherNetworks) {
    errors.otherNetworks = basicLengthAndCharValidation(values?.otherNetworks, 1, 100);
  }

  if (!values.howYouHear) {
    errors.howYouHear = 'Please select an option';
  }

  if (!values.anythingToAdd) {
    errors.anythingToAdd = 'Please tell us more about you.';
  } else if (values.anythingToAdd) {
    errors.anythingToAdd = basicLengthValidation(values?.anythingToAdd, 5, 1000);
  }

  if (!values.acceptTOS) {
    errors.acceptTOS = 'Please agree to ToS and Policy';
  }

  return errors;
};
