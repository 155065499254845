import React from 'react';
import { useJerkmateOffersContext } from '../../pages/jerkmate2/JerkmateOfferProvider';
import { Gtm } from '../../utils/gtm';
import HeaderPayoutIcon from '../reusables/svg/Jerkmate2/HeaderPayouts';
import HighPerfoIcon from '../reusables/svg/Jerkmate2/HighPerfo';
import LandingPagesIcon from '../reusables/svg/Jerkmate2/LandingPages';
import TrackingIcon from '../reusables/svg/Jerkmate2/Tracking';
import parse from 'react-html-parser';

const JerkmateAdvantageParent = ({ children, href }) => {
  const className = 'advantage-box';

  if (href) {
    return (
      <a href={href} className={className} target="_blank">
        {children}
      </a>
    );
  }

  return <div className={className}>{children}</div>;
};

const JerkmateHeader = () => {
  const { sliderSectionRef } = useJerkmateOffersContext();

  const advantages = [
    {
      description: '$100M+ paid in<br />affiliate commissions',
    },
    {
      description: '<u>Official sponsor of</u><br />the Pillow Talk<br />podcast',
      href: ' https://www.youtube.com/@pillowtalkwithryan',
    },
    {
      description: '#1 best-converting<br />adult site (USA)',
    },
    {
      description: 'Machine learning-<br />powered landing<br />page rotation',
    },
  ];

  const scrollToSlider = () => {
    const siteHeaderHeight = document.getElementById('site-header').offsetHeight;
    const target = sliderSectionRef.current;
    window.scroll({ top: target.offsetTop - siteHeaderHeight - 15, behavior: 'smooth' });
    Gtm.event(`jerkmate page`, 'Click', 'access_offers');
  };

  return (
    <div className="header-box">
      <div className="description-box">
        <h1>
          Promote the
          <br />
          <span className="jerkmate-pink">Best-Paying</span> Cam
          <br />
          Offers in the
          <br className="brDesktop" />
          <span> Industry</span>
        </h1>
        <p>
          Earn your biggest payouts yet with the largest and best-converting cam site. <br /> Gear yourself up with our exclusive
          and dedicated support!
        </p>
        <div className="jerkmate-btn" onClick={scrollToSlider}>
          See All Jerkmate Offers
        </div>
      </div>
      <div className="advantages-box">
        {advantages.map((advantage, index) => (
          <React.Fragment key={index}>
            <JerkmateAdvantageParent href={advantage.href}>
              <div className="imgCircle">
                <img alt="Jerkmate" className="jerky-coin-right-top" src={`/img/jerkmate2/jerky-header-icon-${index}.svg`} />
              </div>
              <p>{parse(advantage.description)}</p>
            </JerkmateAdvantageParent>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default JerkmateHeader;
