import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { reduxForm } from 'redux-form';
import validate from '../pages/smart-link/validateSmartlinkForm.js';
import SmartLinkAdTool from './smart-link/SmartLinkAdTool.react.js';
import SmartLinkCustomize from './smart-link/SmartLinkCustomize.react.js';
import { AlertHeader } from '../components/reusables';

import { fetchTrackingLink, fetchOffer, fetchOfferUrl } from './offer/Offer-Actions/Offer-Actions';
import { clearObject } from '../utils/helpers';

import { Gtm } from '../utils/gtm';
import intercom from '../utils/intercom';
import SkinConfigurationsUtils from '../utils/SkinConfigurationsUtils';

import config from '../config';
import { initialFetching } from '../utils/initialFetching';
import SmartlinkChoice from './smart-link/SmartlinkChoice';
import { injectIntl } from 'react-intl';
import { SliderProvider } from './smart-link/SliderContext.react';
import Slider from './smart-link/Slider.react';
import LinkSettings from './smart-link/LinkSettings';

const { SMART_LINKS } = config;

const SmartLink = ({
  affId,
  creatives,
  creativesSearchForm,
  creativesSizes,
  creativesIsFetching,
  creativesSizesIsFetching,
  dispatch,
  fields,
  location,
  offerOverlay,
  offerPopCode,
  offersCreativesViewMode,
  smartLinks,
  skinConfigurations,
  trackingLink,
  userEmail,
  values,
  listMediaPlacements,
  offerIsFetching,
  profileData,
  errors,
  intl,
}) => {
  const linkSettingsButtonRef = useRef(null);
  const [selectedOffer, setSelectedOffer] = useState('');
  const [windowWidthSize, setWindowWidthSize] = useState(undefined);
  const timeoutRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const fecthData = () => {
    if (!offerIsFetching) {
      const smartLinkDatingOfferId = SMART_LINKS[SMART_LINKS.topLabel].id;
      dispatch(fetchOffer(smartLinkDatingOfferId));
      dispatch(fetchOfferUrl(smartLinkDatingOfferId));
    }
  };

  const formChangedHandler = () => {
    const formattedValues = {
      ...values,
      bo: values?.bo?.toString(),
      offer_id: values.offer_id === '0' ? SMART_LINKS[SMART_LINKS.topLabel].id : values.offer_id,
    };
    dispatch(fetchTrackingLink(formattedValues.offer_id, clearObject({ ...formattedValues, offer_id: undefined })));
  };

  useEffect(() => {
    initialFetching(dispatch);
    intercom.trackEvent('Smartlink');
    fecthData();
    SkinConfigurationsUtils.manageAccess(browserHistory, skinConfigurations, 'smartlink');
    formChangedHandler();
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isOpen) {
        formChangedHandler();
      }
    }, 250);
    return () => clearTimeout(delayDebounceFn);
  }, [JSON.stringify(values), isOpen]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidthSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onSmartlinkCopy = (toolName) => {
    Gtm.newEvent('smartlink_step3', false, 'copy', 'string', toolName ?? 'link');
    intercom.update(profileData, {
      company: { latest_copied_link_offer: 'SmartLink' },
    });
    intercom.trackEvent('Smartlink-copy');
  };

  const isCurrentlySelectedCamSmartLink = (currentSelectedDropdownSmartLinkValuesId) =>
    +smartLinks.offerCam.id === +currentSelectedDropdownSmartLinkValuesId;

  const isCurrentlySelectedDatingSmartLink = (currentSelectedDropdownSmartLinkValuesId) =>
    +smartLinks.offerDating.id === +currentSelectedDropdownSmartLinkValuesId;

  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.searchParams.get('ad-tool')) {
      timeoutRef.current = setTimeout(() => {
        const adToolsList = document.getElementById('offer-creatives');
        if (!adToolsList) {
          return;
        }
        adToolsList.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      }, 3000);
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const getBackOffers = (offerId) => {
    let backOffers;
    for (const [, value] of Object.entries(smartLinks)) {
      if (value.id === parseInt(offerId)) {
        backOffers = value.backOffers;
      }
    }

    return backOffers;
  };

  useEffect(() => {
    const application = document.getElementById('application');
    if (isOpen && windowWidthSize <= 576) {
      application.style.overflow = 'hidden';
    } else {
      application.style.overflow = '';
    }
  }, [isOpen, windowWidthSize]);

  const getSmartlinkSlide = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <SmartlinkChoice
            fields={fields}
            formChangedHandler={formChangedHandler}
            getBackOffers={getBackOffers}
            intl={intl}
            isCurrentlySelectedCamSmartLink={isCurrentlySelectedCamSmartLink}
            isCurrentlySelectedDatingSmartLink={isCurrentlySelectedDatingSmartLink}
            key={stepIndex}
            selectedOffer={selectedOffer}
            setSelectedOffer={setSelectedOffer}
            smartLinks={smartLinks}
            values={values}
          />
        );
      case 1:
        return (
          <SmartLinkCustomize
            dispatch={dispatch}
            errors={errors}
            fields={fields}
            formChangedHandler={formChangedHandler}
            getBackOffers={getBackOffers}
            isCurrentlySelectedCamSmartLink={isCurrentlySelectedCamSmartLink}
            isCurrentlySelectedDatingSmartLink={isCurrentlySelectedDatingSmartLink}
            key={stepIndex}
            listMediaPlacements={listMediaPlacements}
            smartLinks={smartLinks}
            values={values}
            windowWidthSize={windowWidthSize}
          />
        );
      case 2:
        return (
          <SmartLinkAdTool
            affId={affId}
            buttonRef={linkSettingsButtonRef}
            creatives={creatives}
            creativesIsFetching={creativesIsFetching}
            creativesSearchForm={creativesSearchForm}
            creativesSizes={creativesSizes}
            creativesSizesIsFetching={creativesSizesIsFetching}
            dispatch={dispatch}
            fields={fields}
            formChangedHandler={formChangedHandler}
            getBackOffers={getBackOffers}
            isCurrentlySelectedCamSmartLink={isCurrentlySelectedCamSmartLink}
            isCurrentlySelectedDatingSmartLink={isCurrentlySelectedDatingSmartLink}
            isOpen={isOpen}
            key={stepIndex}
            location={location}
            offerOverlay={offerOverlay}
            offerPopCode={offerPopCode}
            offersCreativesViewMode={offersCreativesViewMode}
            onSmartlinkCopy={onSmartlinkCopy}
            setIsOpen={setIsOpen}
            trackingLink={trackingLink}
            userEmail={userEmail}
            values={values}
            windowWidthSize={windowWidthSize}
          />
        );
    }
  };

  const backOffers = getBackOffers(values.offer_id);
  return (
    <div className="container-fluid app-container" id="smart-link">
      {windowWidthSize <= 576 && isOpen ? <div className="dimmer" /> : null}
      {windowWidthSize <= 576 && (
        <LinkSettings
          backOffers={backOffers}
          fields={fields}
          formChangedHandler={formChangedHandler}
          isOpen={isOpen}
          linkSettingsButtonRef={linkSettingsButtonRef}
          setIsOpen={setIsOpen}
        />
      )}
      <AlertHeader className="container-fluid app-container smartlink-alert" iconIsVisible localStorageString="smartlink-alert">
        <span>
          SmartLinks must be promoted in accordance with CrakRevenue’s Terms & Conditions. No incentive, Chat, Spam or Mailing
          traffic allowed.
        </span>
      </AlertHeader>
      <SliderProvider setSelectedOffer={setSelectedOffer}>
        <Slider>{[0, 1, 2].map((index) => getSmartlinkSlide(index))}</Slider>
      </SliderProvider>
    </div>
  );
};

SmartLink.propTypes = {
  affId: PropTypes.string.isRequired,
  creatives: PropTypes.object.isRequired,
  creativesIsFetching: PropTypes.bool.isRequired,
  creativesSearchForm: PropTypes.object,
  creativesSizes: PropTypes.array.isRequired,
  creativesSizesIsFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  offerOverlay: PropTypes.any,
  offerPopCode: PropTypes.any,
  offersCreativesViewMode: PropTypes.string.isRequired,
  profileData: PropTypes.any.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
  smartLinks: PropTypes.object.isRequired,
  trackingLink: PropTypes.string,
  userEmail: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
};

export default connect((state) => ({
  affId: state.profile.data.affUserInfos ? state.profile.data.affUserInfos.affiliate_id : '',
  creatives: state.creatives.data,
  creativesSearchForm: state.form.creativesSearch || {},
  creativesIsFetching: state.creatives.isFetching,
  creativesSizes: state.creativesSizes.data,
  creativesSizesIsFetching: state.creativesSizes.isFetching,
  listMediaPlacements: state.application.lists.searchFields.MediaPlacements,
  offerOverlay: state.offer.offerOverlay,
  offerPopCode: state.offer.offerPopCode,
  offersCreativesViewMode: state.application.ui.offersCreativesViewMode,
  offerIsFetching: state.offer.offerIsFetching,
  profileData: state?.profile?.data,
  skinConfigurations: state.skinConfigurations.data,
  smartLinks: SMART_LINKS,
  trackingLink: state.offer.trackingLink,
  userEmail: state.profile.data.affUserInfos ? state.profile.data.affUserInfos.email : '',
}))(
  reduxForm(
    {
      form: 'smartLink',
      fields: ['source', 'aff_sub', 'aff_sub2', 'aff_sub3', 'aff_sub4', 'bo', 'popUnder', 'offer_id', 'target', 'offer'],
      validate,
    },
    () => ({
      initialValues: {
        bo: SMART_LINKS[SMART_LINKS.topLabel].backOffers || undefined,
        popUnder: true,
        offer_id: SMART_LINKS[SMART_LINKS.topLabel].id.toString(),
        target: undefined,
        aff_sub: '',
        aff_sub2: '',
        aff_sub3: '',
        aff_sub4: '',
        source: '',
      },
    })
  )(injectIntl(SmartLink))
);
