import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Copy } from '../reusables';

const CopyValueWidget = ({
  classNames,
  dispatch,
  icon,
  id,
  label,
  labelTxt,
  onCopy,
  onDelete,
  onSelect,
  value,
  isReferrals = false,
  isSmartlink = false,
}) => (
  <div className={['copy-value-widget', ...classNames].join(' ')} id={id}>
    {label || labelTxt ? (
      <div className="your-link">
        {icon ? icon : null}
        {labelTxt ? <span>{labelTxt}</span> : <FormattedMessage {...label} />}
      </div>
    ) : null}
    <input className="copy-value-display" id={`${id}-value`} onClick={onSelect} readOnly value={value || ''} />
    <div className="actions-section">
      <Copy
        buttonStyled
        clipboardTarget={`#${id}-value`}
        dispatch={dispatch}
        isReferrals={isReferrals}
        isSmartlink={isSmartlink}
        onCopy={onCopy}
        text={isSmartlink ? 'copy' : ''}
      />
      {onDelete ? (
        <span className="btn-flat btn-delete" onClick={onDelete}>
          <i className="material-icons">delete</i>
        </span>
      ) : null}
    </div>
  </div>
);

CopyValueWidget.defaultProps = {
  classNames: [],
  onCopy: () => {},
  onSelect: () => {},
};

CopyValueWidget.propTypes = {
  classNames: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.any,
  labelTxt: PropTypes.any,
  onCopy: PropTypes.func,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  value: PropTypes.any,
};

export default CopyValueWidget;
